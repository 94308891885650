import React, { FC } from "react";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "ckeditor5-build-classic-plus";
import { getHeaders } from "../../../interceptor/axiosInstance";
import Error from "../Error";
import "./editor.scss";

interface EditorProps {
  onChangeHandler: (content: string) => void;
  value: string;
  error?: string;
  readOnly?: boolean;
  editorInstanceRef: React.MutableRefObject<any>;
}

const headers = getHeaders();

const Editor: FC<EditorProps> = ({
  value,
  onChangeHandler,
  error,
  readOnly,
  editorInstanceRef,
}) => {
  return (
    <div className="editor">
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={{
          simpleUpload: {
            uploadUrl: "https://google.com/test",
            headers: headers,
          },
          isReadOnly: !!readOnly,
        }}
        onReady={(editor: any) => {
          // Store the editor instance in the reference
          editorInstanceRef.current = editor;
          // editorInstanceRef = editor;
        }}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          onChangeHandler(data);
        }}
        onFileUploadRequest
      />
      <Error message={error ?? ""} />
    </div>
  );
};

export default Editor;
