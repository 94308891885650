import React, { useEffect, useRef, useState } from "react";
import "./reportForm.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import { Button, Col, Menu, Popover, Row, Modal, Input } from "antd";
import AppLoader from "../AppLoader";
import { Report } from "../../../models/Report/report.model";
import { Form, Formik, FormikValues } from "formik";
import Editor from "../Editor";
import { reportFormValidation } from "./reportFormValidation";
import { ReportTemplate } from "../../../models/ReportTemplate/reportTemplate.model";
import {
  getDropdownOptions,
  IDropdownOptions,
} from "../../utils/dropdownUtils";
import ReportTemplateService from "../../../services/ReportTemplate/reportTemplate.service";
import DropdownField from "../DropdownField";
import ReportService from "../../../services/Report/report.service";
import {
  EyeOutlined,
  DownloadOutlined,
  SoundOutlined,
  AudioOutlined,
  AudioMutedOutlined,
} from "@ant-design/icons";
import { DownloadFileTypeEnum } from "../../../enums/downloadFileType.enum";
import AdminCaseService from "../../../services/Case/AdminCase/adminCase.service";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import DoctorCaseService from "../../../services/Case/DoctorCase/doctorCase.service";
import { Doctor } from "../../../models/Doctor/doctor.model";
import { Admin } from "../../../models/Admin/admin.model";
import { Case } from "../../../models/Case/case.model";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import Notification from "../Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { AdminFeatureEnum } from "../../../enums/AdminFeature.enum";
import ApiRoutes from "../../../routes/routeConstants/apiRoutes";
import axios from "axios";
import { AdminFeature } from "../../../models/AdminFeature/adminFeature.model";
import { DoctorFeature } from "../../../models/DoctorFeature/doctorFeature.model";

declare var MediaRecorder: any;
let socket: WebSocket | null = null;

interface ReportFormProps extends AuthReducerProps {
  caseDetailId: number;
  onPublish: () => void;
  user: Doctor | Admin;
  onClose?: () => void;
  fromViewer?: boolean;
  content: string;
  values: { content: string }; // Assuming your form has a 'content' field
  setFieldValue: (field: string, value: string) => void;
  errors: { content: string };
}

function ReportForm({
  caseDetailId,
  userRole,
  onPublish,
  onClose,
  user,
  values,
  setFieldValue,
  fromViewer = false,
}: ReportFormProps) {
  const [caseDetail, setCaseDetail] = useState<CaseDetail>();

  const [formValues, setFormValues] = useState(new Report());

  const [reportTemplates, setReportTemplates] = useState<ReportTemplate[]>([]);

  const [reportTemplateOptions, setReportTemplateOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [formLoading, setFormLoading] = useState(false);

  const [publishLoading, setPublishLoading] = useState(false);

  const [pdfLoading, setPdfLoading] = useState(false);

  const [wordLoading, setWordLoading] = useState(false);

  const [caseLoading, setCaseLoading] = useState(false);

  const debounceTimerRef: any = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");

  const [voiceLoading, setVoiceLoading] = useState(false);

  const [content, setContent] = useState(""); // CKEditor content state
  // const recognitionRef = useRef<SpeechRecognition | null>(null); // Ref to manage SpeechRecognition instance
  const editorInstanceRef = useRef<any>(null);
  const [timer, setTimer] = useState(0); // Timer in seconds
  const [totalTimer, setTotalTimer] = useState(0); // Timer in seconds
  const [sessionTimer, setSessionTimer] = useState(0); // Timer in seconds
  const [isMuted, setIsMuted] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [aiReportLoading, setAiReportLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVoiceToTextEnabled, setVoiceToTextEnabled] = useState(false);
  const [isAiReportingEnabled, setAiReportingEnabled] = useState(false);
  const [aiCreditsUsed, setAiCreditsUsed] = useState(0);
  const [aiTotalCredits, setAiTotalCredits] = useState(0);
  const [isAiReportingAllowed, setAiReportingAllowed] = useState(false);

  const [textareaValue, setTextareaValue] = useState("");

  const [template, setTemplate] = useState("");
  const [doctorInput, setDoctorInput] = useState("");
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (caseDetail) {
      clearTimeout(debounceTimerRef.current);
      debounceTimerRef.current = setTimeout(() => {
        handleFetchReportTemplates(searchTerm);
      }, 500);
    }
  }, [searchTerm]);

  const startSpeechRecognition = (
    values: FormikValues,
    setFieldValue: (field: string, value: any) => void
  ) => {
    // check if user breaches 5 minute threshold to use voice to text feature
    if (timer + 300 > totalTimer) {
      Notification({
        message:
          "Voice to text feature time limit exceeded. Contact Support Team",
        type: NotificationTypes.ERROR,
      });
      return false;
    }
    let silenceTimeout: NodeJS.Timeout | null = null;
    setVoiceLoading(true);
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      let previousTranscript = "";
      if (!("MediaRecorder" in window)) {
        return alert("Browser not supported");
      }

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: "audio/webm",
      });

      socket = new WebSocket("wss://api.staging.radiolinq.com/api/voice");

      socket.onopen = () => {
        setIsRunning(true);
        setIsMuted(false);
        Notification({
          message: "Speech detection activated!",
          type: NotificationTypes.SUCCESS,
        });
        mediaRecorder.addEventListener("dataavailable", async (event: any) => {
          if (event.data.size > 0 && socket!.readyState == 1) {
            socket!.send(event.data);
          }
        });
        mediaRecorder.start(1000);
        resetSilenceTimeout(); // Start silence timer
      };

      socket.onmessage = (message) => {
        const transcript = message.data;

        if (transcript) {
          // Append to CKEditor
          const editorElement = document.querySelector(
            ".editor .ck-editor__editable"
          );
          const textareaElement = document.querySelector("textarea");

          if (document.activeElement === editorElement) {
            appendAtCursor(transcript);
          } else if (document.activeElement === textareaElement) {
            // If the textarea is focused, update its value
            setTextareaValue((prevValue) => prevValue + transcript);
          } else {
            // Default to CKEditor if nothing else is focused
            appendToEnd(transcript);
          }
        }
        resetSilenceTimeout();
      };

      // Function to append text at the cursor
      const appendAtCursor = (transcript: string) => {
        const editorInstance = editorInstanceRef.current;
        if (editorInstance) {
          editorInstance.model.change((writer: any) => {
            const selection = editorInstance.model.document.selection;
            const insertPosition = selection.focus; // Cursor position
            writer.insertText(transcript + " ", insertPosition);
          });
        }
      };

      // Function to append text at the end of CKEditor
      const appendToEnd = (transcript: string) => {
        const editorInstance = editorInstanceRef.current;
        if (editorInstance) {
          const currentContent = editorInstance.getData(); // Get current content
          let updatedContent = currentContent;

          // Check if the last character is a line break or paragraph tag
          if (
            updatedContent.endsWith("</p>") ||
            updatedContent.endsWith("<br>")
          ) {
            // If it ends with a line break or paragraph, append the transcript within the same block
            updatedContent =
              updatedContent.replace(/<\/p>$/, "") + " " + transcript;
          } else {
            // Otherwise, append the text with a space and keep it within the same paragraph
            updatedContent = updatedContent + " " + transcript;
          }

          // Update the editor content
          editorInstance.setData(updatedContent);
        }
      };

      socket.onclose = () => {
        mediaRecorder.stop();
        storeVoiceToTextSession();
        Notification({
          message: "Speech detection ended!",
          type: NotificationTypes.SUCCESS,
        });
        // Stop all tracks in the MediaStream to release the microphone
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
        if (silenceTimeout) {
          clearTimeout(silenceTimeout);
          silenceTimeout = null;
        }
      };

      socket.onerror = (error) => {
        console.log({ event: "onerror", error });
      };
    });

    function resetSilenceTimeout() {
      if (silenceTimeout) clearTimeout(silenceTimeout);
      silenceTimeout = setTimeout(() => {
        // alert(timer)
        // alert(sessionTimer);
        stopSpeechRecognition();
        // setTimer(0);
        setSessionTimer(0);
      }, 30000);
    }
  };

  function storeVoiceToTextSession() {
    ReportService.storeVoiceToTextSessionData(
      userRole,
      user.id,
      sessionTimer,
      caseDetail?.id,
      (response: any) => {
        setVoiceToTextEnabled(true);
        setTimer(response.data.used_credits);
        setTotalTimer(response.data.subscribed_credits);
        setIsMuted(true);
        setIsRunning(false);
        setSessionTimer(0);
      },
      () => {},
      () => {}
    );
    setVoiceLoading(false);
  }

  function storeAiReportingSession() {
    ReportService.storeAiReportingSessionData(
      userRole,
      user.id,
      1,
      caseDetail?.id,
      (response: any) => {
        setAiCreditsUsed(response.data.used_credits);
        setAiTotalCredits(response.data.subscribed_credits);
        setAiReportingAllowed(response.data.allowed);
      },
      () => {},
      () => {}
    );
    setVoiceLoading(false);
  }

  const stopSpeechRecognition = () => {
    if (socket && socket.readyState !== 3) {
      socket.close();
      storeVoiceToTextSession();
    }
  };

  const removeBgColor = (text: string): string => {
    return text.replace(
      /<span[^>]*style=["'][^"']*background-color:\s*yellow;?[^"']*["'][^>]*>(.*?)<\/span>/gi,
      "$1"
    );
  };

  const smartReporting = (
    values: FormikValues,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const report = Object.assign(new Report(), values);
    if (report.content === undefined) {
      Notification({
        message: "Please select a report template for Smart reporting",
        type: NotificationTypes.ERROR,
      });
    } else {
      setIsModalOpen(true);
    }
  };

  const generateGeminiReport = async (
    textContent: string,
    values: FormikValues
  ) => {
    try {
      stopSpeechRecognition();
      if (aiCreditsUsed + 5 > aiTotalCredits) {
        Notification({
          message:
            "Voice to text feature time limit exceeded. Contact Support Team",
          type: NotificationTypes.ERROR,
        });
        return false;
      }
      const report = Object.assign(new Report(), values);
      console.log(report);
      if (report) {
        setAiReportLoading(true);
        const editorInstance = editorInstanceRef.current;
        editorInstance.isReadOnly = true; // Disable editor
        editorInstance.setData(
          "<p><em>🤔 AI is generating the report... Please wait.</em></p>"
        );

        ReportService.generateGeminiReport(
          report,
          report.content,
          textContent,
          (generatedText: string) => {
            onPublish && onPublish();
            generatedText = generatedText.replace(/```html|```/g, "").trim();
            editorInstance.setData(generatedText);
            editorInstance.isReadOnly = false;
            storeAiReportingSession();
          },
          () => {},
          () => {
            setAiReportLoading(false);
            setTextareaValue("");
          }
        );
      }
    } catch (error) {
      console.error("Gemini AI reporting error");
    }
  };

  const handleDownloadReport = (
    status: boolean | undefined,
    fileType: DownloadFileTypeEnum,
    withLetterHead: boolean
  ) => () => {
    if (caseDetail?.id) {
      setPdfLoading(true);
      if (status) {
        ReportService.downloadReport(
          fileType,
          caseDetail,
          withLetterHead,
          (reportUrl: string) => {
            window.open(reportUrl, "_blank");
          },
          () => {},
          () => {
            setPdfLoading(false);
            setWordLoading(false);
          }
        );
      } else {
        const apiMethod =
          caseDetail?.status === CaseStatusEnum.TO_BE_APPROVED
            ? ReportService.previewApprovalReport
            : ReportService.previewReport;
        apiMethod(
          caseDetail?.id,
          (data: any) => {
            const url = window.URL.createObjectURL(
              new Blob([data], { type: "application/pdf" })
            );
            const pdfWindow = window.open();
            if (pdfWindow) {
              pdfWindow.location.href = url;
              pdfWindow.document.title = "report";
            }
          },
          () => {},
          () => {
            setPdfLoading(false);
            setWordLoading(false);
          }
        );
      }
    }
  };

  const handlePublishReport = (values: FormikValues) => () => {
    const report = Object.assign(new Report(), values);
    // @ts-ignore
    report.content = removeBgColor(report.content);
    if (report && report.id) {
      setPublishLoading(true);
      ReportService.publishReport(
        report,
        (report: Report) => {
          setFormValues(report);
          onPublish && onPublish();
        },
        () => {},
        () => {
          setPublishLoading(false);
        }
      );
    }
  };

  const handleApproveAndPublishReport = (values: FormikValues) => () => {
    const report = Object.assign(new Report(), values);
    // @ts-ignore
    report.content = removeBgColor(report.content);
    if (report && report.id) {
      setPublishLoading(true);
      ReportService.approveAndPublishReport(
        report,
        (report: Report) => {
          setFormValues(report);
          onPublish && onPublish();
        },
        () => {},
        () => {
          setPublishLoading(false);
        }
      );
    }
  };

  const handleSendForApproval = (values: FormikValues) => () => {
    const report = Object.assign(new Report(), values);
    // @ts-ignore
    report.content = removeBgColor(report.content);
    if (report && report.id) {
      setPublishLoading(true);
      ReportService.sendForApproval(
        report,
        caseDetailId,
        (report: Report) => {
          setFormValues(report);
          onPublish && onPublish();
          onClose && onClose();
        },
        () => {},
        () => {
          setPublishLoading(false);
        }
      );
    }
  };

  const handleSubmit = (values: Report) => {
    const report = Object.assign(new Report(), values);
    // @ts-ignore
    report.content = removeBgColor(report.content);
    report.caseDetailId = caseDetail?.id;
    setFormLoading(true);
    if (report.id) {
      ReportService.updateReport(
        report,
        () => {
          setFormValues(report);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      ReportService.createReport(
        report,
        (report: Report) => {
          setFormValues(report);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  const caseDetails = [
    { label: "Patient Name", value: caseDetail?.patientName },
    {
      label: "Age / Gender",
      value: `${caseDetail?.age} / ${caseDetail?.gender}`,
    },
    {
      label: "Scan Type",
      value: caseDetail?.scanTypeName,
    },
    {
      label: "Body Parts",
      value: caseDetail?.caseBodyParts
        ?.map((caseBodyPart) => caseBodyPart.bodyPartName)
        .join(", "),
    },
    {
      label: "",
      value: formValues?.id ? (
        formValues?.status ? (
          <Popover
            destroyTooltipOnHide
            overlayClassName="admin-cases__assignment-popover"
            placement="bottomLeft"
            content={
              <div>
                <Menu mode="vertical" className="border-none">
                  <Menu.Item
                    className="navbar-item m-0"
                    key="with_letterhead"
                    onClick={handleDownloadReport(
                      formValues?.status,
                      DownloadFileTypeEnum.PDF,
                      true
                    )}
                  >
                    Download with Letterhead
                  </Menu.Item>
                  <Menu.Item
                    className="navbar-item m-0"
                    key="without_letterhead"
                    onClick={handleDownloadReport(
                      formValues?.status,
                      DownloadFileTypeEnum.PDF,
                      false
                    )}
                  >
                    Download without Letterhead
                  </Menu.Item>
                  <Menu.Item
                    className="navbar-item m-0"
                    key="as_letterhead"
                    onClick={handleDownloadReport(
                      formValues?.status,
                      DownloadFileTypeEnum.WORD,
                      false
                    )}
                  >
                    Download as Word
                  </Menu.Item>
                </Menu>
              </div>
            }
            title="Download"
            trigger="click"
          >
            <Button type="primary" loading={pdfLoading}>
              <React.Fragment>
                <DownloadOutlined className="mr-1" />
                Download
              </React.Fragment>
            </Button>
          </Popover>
        ) : (
          <Button
            type="primary"
            onClick={handleDownloadReport(
              formValues?.status,
              DownloadFileTypeEnum.PDF,
              false
            )}
            loading={pdfLoading}
          >
            <React.Fragment>
              <EyeOutlined className="mr-1" />
              Preview PDF
            </React.Fragment>
          </Button>
        )
      ) : (
        ""
      ),
    },
  ];

  const handleFetchReportTemplates = (searchText: string) => {
    ReportTemplateService.fetchReportTemplates(
      searchText,
      caseDetail?.scanTypeId,
      (reportTemplates: ReportTemplate[]) => {
        setReportTemplates(reportTemplates);
        setReportTemplateOptions(
          getDropdownOptions(reportTemplates, "name", "id")
        );
      },
      () => {},
      () => {}
    );
  };

  const setVoiceAndAiFeature = () => {
    const featuresMap: Record<string, any> = {
      voice_to_text: {
        enabled: false,
        setter: setVoiceToTextEnabled,
        serviceCall: ReportService.getVoiceToTextInfo,
        updateState: (response: any) => {
          setTimer(response.data.used_credits);
          setTotalTimer(response.data.subscribed_credits);
          setSessionTimer(0);
        },
      },
      ai_reporting: {
        enabled: false,
        setter: setAiReportingEnabled,
        serviceCall: ReportService.getAiReportingInfo,
        updateState: (response: any) => {
          setAiCreditsUsed(response.data.used_credits);
          setAiTotalCredits(response.data.subscribed_credits);
          setAiReportingAllowed(response.data.allowed);
        },
      },
    };

    const userFeatures =
      userRole === UserRoleEnum.ADMIN
        ? user.adminFeatures
        : user.doctorFeatures;

    userFeatures?.forEach((feature: any) => {
      if (featuresMap[feature.feature]) {
        featuresMap[feature.feature].enabled = true;
      }
    });

    Object.values(featuresMap).forEach(
      ({ enabled, setter, serviceCall, updateState }) => {
        if (enabled) {
          serviceCall(
            userRole,
            user.id,
            (response: any) => {
              setter(true);
              updateState(response);
            },
            () => {},
            () => {}
          );
        }
      }
    );
  };

  useEffect(() => {
    if (caseDetail?.id) {
      const screenWidth = window.innerWidth;
      setVoiceAndAiFeature();
      handleFetchReportTemplates("");
      if (caseDetail?.status === CaseStatusEnum.TO_BE_APPROVED) {
        ReportService.showAdminReport(
          caseDetailId,
          (report: Report) => {
            setFormValues(report);
          },
          () => {
            setFormValues(new Report());
          },
          () => {}
        );
      } else {
        ReportService.showReport(
          caseDetailId,
          (report: Report) => {
            setFormValues(report);
          },
          () => {
            setFormValues(new Report());
          },
          () => {}
        );
      }
    }
  }, [caseDetail]);

  useEffect(() => {
    if (caseDetailId) {
      const showMethod =
        userRole === UserRoleEnum.ADMIN
          ? AdminCaseService.showAdminCase
          : DoctorCaseService.showDoctorCase;
      showMethod(
        caseDetailId,
        (caseDetail: CaseDetail) => {
          setCaseDetail(caseDetail);
        },
        () => {},
        () => {}
      );
    }
  }, [caseDetailId]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null; // Explicitly typing interval

    if (isRunning) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
        setSessionTimer((prev) => prev + 1);
      }, 1000);
    } else if (interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isRunning]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "auto"; // ✅ Re-enable scrolling
    } else {
      document.body.style.overflow = ""; // ✅ Reset when modal closes
    }
  }, [isModalOpen]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div className="report-form">
      {caseLoading ? (
        <AppLoader loading={caseLoading} />
      ) : (
        <div>
          <Row gutter={[20, 20]}>
            {caseDetails?.map((caseDetail, i) => (
              <Col span={6} key={i}>
                <div className="report-form__label">{caseDetail?.label}</div>
                <div className="report-form__value">{caseDetail?.value}</div>
              </Col>
            ))}
          </Row>
          {!formValues?.status && (
            <Formik
              initialValues={formValues}
              onSubmit={handleSubmit}
              enableReinitialize
              validationSchema={reportFormValidation}
            >
              {({ values, errors, isValid, setFieldValue }) => {
                let screenWidth = window.innerWidth;
                return (
                  <Form>
                    <Row gutter={[0, 20]}>
                      <Col span={fromViewer ? 24 : 12}>
                        <DropdownField
                          allowClear
                          placeHolder="Select Template"
                          showSearch
                          title="Report Template"
                          name="reportTemplateId"
                          onSearch={(value) => {
                            setSearchTerm(value);
                          }}
                          onClear={() => {
                            setSearchTerm("");
                          }}
                          onChange={(value) => {
                            setFieldValue("reportTemplateId", value);
                            const template = reportTemplates.find(
                              (template) => template.id === value
                            );
                            if (template) {
                              setFieldValue(
                                "content",
                                values.content
                                  ? // ? values?.content + "<br/>" + template.content
                                    template.content
                                  : template.content
                              );
                            }
                          }}
                          options={reportTemplateOptions}
                        />
                      </Col>
                      <Col span={24}>
                        <React.Fragment>
                          {isVoiceToTextEnabled ? (
                            <React.Fragment>
                              {" "}
                              {}
                              <Button
                                type="primary"
                                onClick={() => {
                                  if (voiceLoading) {
                                    stopSpeechRecognition();
                                  } else {
                                    startSpeechRecognition(
                                      values,
                                      setFieldValue
                                    );
                                  }
                                }}
                              >
                                {isMuted ? (
                                  <AudioMutedOutlined />
                                ) : (
                                  <AudioOutlined />
                                )}
                              </Button>
                              <span style={{ marginLeft: "10px" }}>
                                <span>
                                  {formatTime(timer)} / {formatTime(totalTimer)}
                                </span>
                              </span>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>

                        <React.Fragment>
                          {isAiReportingEnabled ? (
                            <>
                              <Button
                                type="primary"
                                loading={aiReportLoading}
                                // disabled={publishLoading}
                                onClick={() => {
                                  smartReporting(values, setFieldValue);
                                }}
                                style={{
                                  ...(isVoiceToTextEnabled && screenWidth> 900
                                    ? { marginLeft: "10px" }
                                    : {}),
                                }}
                              >
                                Try Radiolinq AI Assist
                              </Button>
                              <span style={{ marginLeft: "10px" }}>
                                <span>
                                  {aiCreditsUsed} / {aiTotalCredits} credits
                                </span>
                              </span>
                            </>
                          ) : null}
                        </React.Fragment>
                      </Col>

                      {isModalOpen && (
                        <>
                          <Input.TextArea
                            rows={4}
                            value={textareaValue}
                            onChange={(e) => setTextareaValue(e.target.value)}
                            placeholder="Provide medical finding pointers"
                          />
                          <Button
                            key="cancel"
                            onClick={() => setIsModalOpen(false)}
                            style={{ marginRight: "10px" }}
                          >
                            Cancel
                          </Button>
                          <Button
                            key="submit"
                            type="primary"
                            onClick={() => {
                              if (textareaValue.length == 0) {
                                alert(
                                  "Please provide medical finding pointers for generating report."
                                );
                              } else {
                                generateGeminiReport(
                                  textareaValue,
                                  values
                                ).then((r) =>
                                  console.log("Generated Smart Report")
                                );
                                setIsModalOpen(false);
                              }
                            }}
                          >
                            Generate Smart Report
                          </Button>
                        </>
                      )}
                      <Col span={24}>
                        <Editor
                          error={errors.content as string}
                          value={values.content || ""}
                          onChangeHandler={(content) => {
                            setFieldValue("content", content);
                          }}
                          editorInstanceRef={editorInstanceRef}
                        />
                      </Col>
                    </Row>
                    <div className="report-form__submit-wrapper text-right">
                      {values?.id &&
                        !values.status &&
                        (user?.approvalRequired ? (
                          <Button
                            className="mr-2"
                            type="primary"
                            htmlType="button"
                            loading={publishLoading}
                            disabled={publishLoading}
                            onClick={handleSendForApproval(values)}
                          >
                            Send for Approval
                          </Button>
                        ) : caseDetail?.status ===
                            CaseStatusEnum.TO_BE_APPROVED &&
                          userRole === UserRoleEnum.ADMIN ? (
                          <Button
                            className="mr-2"
                            type="primary"
                            htmlType="button"
                            loading={publishLoading}
                            disabled={publishLoading}
                            onClick={handleApproveAndPublishReport(values)}
                          >
                            Approve and Publish Report
                          </Button>
                        ) : (
                          <Button
                            className="mr-2"
                            type="primary"
                            htmlType="button"
                            loading={publishLoading}
                            disabled={publishLoading}
                            onClick={handlePublishReport(values)}
                          >
                            Save & Publish Report
                          </Button>
                        ))}
                      {!values.status && (
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={formLoading}
                          disabled={!isValid || formLoading}
                        >
                          Save Report
                        </Button>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      )}
    </div>
  );
}

export default AuthContainer(ReportForm);
